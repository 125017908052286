import './RenderSearchResults.scss';

import React from "react";
import { useIntl } from "react-intl";

import RenderItem from "./RenderItem";

export default function RenderSearchResults (props)  {

    let preloaderItems = 5; // Количество айтемов в прелоадере
    let specItemNumber = -1; // Позиция спецпредложения в списке

    let resultsClass;

    const Intl = useIntl();

    // Обработка вида результатов в зависимости от страницы. Результаты поиска, или информация о рейсе
    if ( props.flightId ) {
        if ( props.departureBackDate ) {
            resultsClass = "flightinfo-back-results flightinfo-results results";
        } else {
            resultsClass = "flightinfo-results results";
        }
    } else {
        resultsClass = "results";
    }
    if ( props.emptyLeg ) { resultsClass = resultsClass + ' emptyleg-results'; }

    // console.log('isLoading: ' + props.isLoading);
    // console.log('airportFromData: ' + props.airportFromData);
    // console.log('airportToData: ' + props.airportToData);
    // console.log('airportsData: ' + props.airportsData);
    // console.log('departureDate: ' + props.departureDate);
       console.log('emptyLeg: ' + props.emptyLeg);

    if (!props.isLoading &&
        (
            (props.airportFromData && props.airportToData && props.airportsData && props.departureDate) ||
            (props.emptyLeg)
        )
    ) {
        if (!props.flightId) {
            specItemNumber = props.flightsData.cards.length;
        }

        return (
            <div className={ resultsClass } id="results">
                { props.flightsData.cards.map((item, index) => (
                    <React.Fragment key = { item.id }>
                        <RenderItem
                            first = { index === 0 }
                            last = { index + 1 === props.flightsData.cards.length }
                            item = { item }
                            airportFromData = { props.airportFromData }
                            airportToData = { props.airportToData }
                            airportsData = { props.airportsData }
                            departureDate = { props.departureDate }
                            departureBackDate = { props.departureBackDate }
                            flightId = { props.flightId }
                            flightInfo = { props.flightInfo }
                            currency = { props.currency }
                            emptyLeg = { props.emptyLeg }
                        />
                        { specItemNumber === index + 1 && !props.emptyLeg &&
                            <React.Fragment>
                                <div className="text-item-splitter" />
                            </React.Fragment>
                        }
                    </React.Fragment>
                )) }
            </div>
        )
    } else {
        if (props.flightsData && props.flightsData.cards.length !== 0) {
            console.log( 'Набор данных для рендера 2: '+ JSON.stringify(props.flightsData.cards, null, 2) );
            return (
                <div className={ resultsClass } id="results">
                    { props.flightsData.cards.map(item => (
                        <RenderItem
                            key = { item.id }
                            isLoading = "true"
                            flightId = { props.flightId }
                            flightInfo = { props.flightInfo }
                            departureBackDate = { props.departureBackDate }
                        />
                    )) }
                </div>
            );
        } else {
            console.log( 'Набор данных для рендера 3: '+ props.flightsData );
            let key = [];
            for (let i=0;i<preloaderItems;i++) { key.push(i); }
            return (
                <div className={ resultsClass } id="results">
                    { key.map(key => (
                        <RenderItem
                            key = { key }
                            isLoading = "true"
                            flightId = { props.flightId }
                            flightInfo = { props.flightInfo }
                            departureBackDate = { props.departureBackDate }
                        />
                    )) }
                </div>
            );
        }
    }
};
