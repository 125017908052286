import React, { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { Link, useNavigate } from "react-router-dom";

import './FirstScreen.scss';
import SearchForm from './SearchForm';
import SearchFormHandler from "./SearchFormHandler";

import emptyleg_icon from '../assets/emptyleg.png';
import sharing_icon from '../assets/sharing.png';
import helicopter_icon from '../assets/helicopter.png';
import background_video from '../assets/background_video.mp4';
import GetFirstRequestDate from "../components/GetFirstRequestDate";    // Определение даты запроса, где изначально не указано время

export default function FirstScreen( props )  {

    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false)
    const [isToSearching, setIsToSearching] = useState(false) // Флаг выбора аэропорта прибытия, если false, тогда выбираем аэропорт вылета
    const [fromSelected, setFromSelected] = useState(null)
    const [toSelected, setToSelected] = useState(null)
    const [departureDate, setDepartureDate] = useState(GetFirstRequestDate(new Date));
    const [pax, setPax] = useState(1);
    const Intl = useIntl();

    let header;
    if ( props.header ) { header = props.header; } else { header = Intl.messages['main_label']; }

    function handleIsSearchingChange() {
        if ( !isSearching ) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "scroll"
        }
        setIsSearching( !isSearching );
        setIsToSearching( false );
    }

    function handleIsToSearchingChange() {
        setIsToSearching( true );
        setIsSearching( true );
    }

    function handleSelectedChange(airport) {
        if (isToSearching) {
            setToSelected(airport);
        } else {
            setFromSelected(airport);
        }
        handleIsSearchingChange();
    }

    function submit() {
        if (fromSelected && toSelected && departureDate) {
            let redirPath = Intl.messages['currentLocaleLink'] + 'search/' + fromSelected.icao + '/' + toSelected.icao + '/' + departureDate.toString() + '/' + pax;
            navigate(redirPath);
        }
    }

    return (
        <div className="firstscreen">
            <video preload="auto" playsInline="" autoPlay={true} loop muted src={background_video} type="video/mp4"></video>
            <h2 className="search-heading">
                <FormattedMessage id='search_flights' />
            </h2>
            <div className="items">
                <SearchForm
                    isSearching = { isSearching }
                    isToSearching = { isToSearching }
                    fromSelected = { fromSelected }
                    toSelected = { toSelected }
                    departureDate = { departureDate }
                    setDepartureDate = { setDepartureDate }
                    pax = { pax }
                    setPax = { setPax }
                    handleIsSearchingChange = { handleIsSearchingChange }
                    handleIsToSearchingChange = { handleIsToSearchingChange }
                    submit = { submit }
                />
                <SearchFormHandler
                    isSearching = { isSearching }
                    isToSearching = { isToSearching }
                    handleIsSearchingChange = { handleIsSearchingChange }
                    handleSelectedChange = { handleSelectedChange }
                    aiportsData = { props.airportsData }
                />
                <div className="subitem emptyleg">
                    <Link to={ useIntl().messages['currentLocaleLink'] + 'el/' }>
                    <div className="header">
                        <span className="icon_mobile"><img height="19" width="19" src={ emptyleg_icon } alt={ Intl.messages['emptyleg'] } /></span>
                        <span className="text">
                            <FormattedMessage id='emptyleg' />
                        </span>
                        <span className="icon_desktop"><img src={ emptyleg_icon } alt={ Intl.messages['emptyleg'] } /></span>
                    </div>
                    <div className="caption">
                        <FormattedMessage id='emptyleg_description' />
                    </div>
                    </Link>
                </div>
                {/* <div className="subitem heli">
                    <a href="https://vertolet.online" target="_blank">
                        <div className="header">
                            <span className="icon_mobile"><img height="24" width="21" src= { helicopter_icon } alt={ Intl.messages['helicopters'] } /></span>
                            <span className="text">
                                <FormattedMessage id='helicopters' /><br/>
                            </span>
                            <span className="icon_desktop"><img src= { helicopter_icon } alt={ Intl.messages['helicopters'] } /></span>
                        </div>
                        <div className="caption"><FormattedMessage id='helicopters_description' /></div>
                    </a>
                </div> */}
                <div className="subitem sharing">
                    <div className="header">
                        <span className="icon_mobile"><img height="21" width="19" src={ sharing_icon } alt={ Intl.messages['jetsharing'] } /></span>
                        <span className="text">
                            <FormattedMessage id='jetsharing' /><br/>
                            <span className="splitter"> · </span>
                            <FormattedMessage id='soon' />
                        </span>
                        <span className="icon_desktop"><img src={ sharing_icon } alt={ Intl.messages['jetsharing'] } /></span>
                    </div>
                    <div className="caption"><FormattedMessage id='jetsharing_description' /></div>
                </div>
            </div>
        </div>
    );
}
